import React from "react";
import { graphql } from "gatsby";

import SEO from "../components/seo";
import PortableText from "../components/portableText";

export const query = graphql`
  query MediaPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      _rawMediaPageContent(resolveReferences: { maxDepth: 5 })
    }
  }
`;

const sectionRegex = /^(?:h\d|normal)$/gi;
const MediaPage = ({ data = {} }) => {
  const mediaPageContent = data.site?._rawMediaPageContent;
  return (
    <>
      <SEO title="Hatepedia in the Media" />
      {mediaPageContent && (
        <div className="prose dark:prose-invert prose-lg md:py-12 media-page-content">
          <PortableText blocks={mediaPageContent} sectionRegex={sectionRegex} />
        </div>
      )}
    </>
  );
};

export default MediaPage;
